@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

:root {
    --msoe-red-color: #C5050C;
    --box-shadow-color: #808080;
    --main-color: #fff;
    --main-background-color: #363636;
    --successful-color: #66dd00;
    --unsuccessful-color: #ff0000;
    --wip-color: #00ffff;
    --scheduled-color: #ffff00;
    --unscheduled-color: #efefef;
    --error-color: rgba(197, 5, 12, 0.5);
    --toggle-shadow: #34323D;
    --table-border: #888;
}

body {
    color: var(--main-color);
    background-color: var(--main-background-color);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 1080px;
    margin: 0 auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    color: var(--main-color);
}

button {
    color: var(--main-color);
    width: 250px;
    background-color: var(--msoe-red-color);
    border: 1px solid var(--msoe-red-color);
    padding: 5px 15px;
    border-radius: 20px;
    box-shadow: 0 0 5px var(--box-shadow-color);
    text-align: center;
    white-space: nowrap;
    font-weight: bold;
    outline: 0 !important;
}
button:hover {
    filter: brightness(95%);
}
button:active {
    filter: brightness(90%);
}
button:disabled {
    opacity: .65;
    filter: brightness(100%) !important;
}

#selectContainer {
    display: flex;
    justify-content: center;
    margin: auto;
}

#errorContainer {
    max-width: 100%;
    background-color: var(--error-color);
    justify-content: center;
    display: flex;
    padding: 7px;
}

#errorLabel {
    opacity: .99;
    text-align: center;
    position: relative;
    font-size: large;
}

#displayNameHeader {
    font-size: 24px;
    text-align: center;
    margin: auto 20px 20px auto;
}

#exportStudentDataBtn {
    width: 225px;
}

/*ul {
    width: 250px;
}*/

.links {
    display: flex;
    width: 50%;
    height: 55px;
    right: 0;
    position: absolute;
    justify-content: flex-end;
}

.links a {
    text-decoration: none;
    letter-spacing: 3px;
    font-size: 18px;
    margin: 0 auto;
    display: block;
}

.links a:hover{
    text-decoration: underline;
}

.links li {
    list-style: none;
    text-align: center;
    align-items: center;
    font-size: 19px;
    font-weight: bold;
    height: 55px;
    display: flex;
    width: 200px;
}

.current {
    background-color: var(--main-background-color);
    color: var(--msoe-red-color);
    border-radius: 5px 5px 0 0;
    width: 200px;
}

.links i {
    margin-left: 20px;
}

h1 {
    text-align: center;
}

th {
    text-align: center;
}

#txt,
#hide {
    /*font: inherit;*/
    margin: 0;
    padding: 0;
}

#txt {
    background-color: var(--main-background-color);
    border: none;
    min-width: 10px;
}

#txt:focus-visible {
    outline: none;
}

.div-draggable {
    display: table;
}

.successful {
    color: var(--successful-color);
}

.projected {
    color: var(--scheduled-color);
}

.unsuccessful {
    color: var(--unsuccessful-color);
}

.untaken {
    color: var(--main-color);
}

.switchLabel, .toggle {
    height: 100%;
    border-radius: 100px;
}
.switchLabel {
    width: 100%;
    border-radius: 100px;
    position: relative;
    margin: 1.8rem 0 4rem 0;
    cursor: pointer;
}
.toggle {
    position: absolute;
    width: 50%;
    background-color: var(--msoe-red-color);
    box-shadow: var(--toggle-shadow);
    transition: transform .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.names {
    font-weight: bolder;
    width: 300px;
    height: 25px;
    position: relative;
    display: flex;
    justify-content: space-around;
    user-select: none;
}

.toggleDiv {
    background-color: var(--toggle-shadow);
    box-shadow: 0 0 5px var(--box-shadow-color);
    margin: 20px auto auto;
    border-radius: 100px;
    border-spacing: 0 0;
    position: relative;
    width: 300px;
}

div#pop-up {
    display: none;
    position: absolute;
    width: auto;
    padding: 6px;
    background: var(--msoe-red-color);
    font-weight: bold;
    box-shadow: 0 0 5px var(--box-shadow-color);
    border-radius: 20px;
    border: var(--msoe-red-color);
    font-size: 90%;
    z-index: 999999 !important;
}

#menu {
    background-color: var(--main-background-color);
    box-shadow: 0 0 5px var(--box-shadow-color);
    justify-content: center;
    max-width: fit-content;
}

#menuSpan {
    display: flex;
    justify-content: center;
    max-width: inherit;
}

#closeButton {
    width: auto;
    background-color: var(--main-background-color);
    box-shadow: 0 0 0;
    border: none;
    margin: 0 0 0;
}

#electiveItems {
    max-height: 150px;
    overflow-y: auto;
}

#electiveInput {
    background-color: var(--main-background-color);
    border: none;
    width: auto;
    padding: 0 0 0;
    margin-right: -20px;
    border: 0;
    border-radius: 0;
}

#electiveLabel {
    text-align: center;
}

#electiveInput:focus-visible {
    outline: none;
}

#instructions {
    font-size: larger;
    text-align: center;
}

h3 {
    text-align: center;
}

table {
    table-layout: fixed;
    width: 100%;
}

table, th, td {
    border: 1px solid var(--table-border);
}

td {
    vertical-align: top;
}

.semester {
    width: 25%;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.column1 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.column2 {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 3;
}

.wip {
    color: var(--wip-color);
}

.scheduled {
    color: var(--scheduled-color);
}

.notTaking {
    color: var(--unsuccessful-color);
}

.tooltiptext {
    color: var(--main-color);
}

.done::before {
    content: "\2713";
}

.scheduled::before {
    content: "\2611";
}

.unscheduled::before {
    content: "\2610";
}

.unsuccessful {
    color: var(--unsuccessful-color);
}

textarea {
    height: 100%;
    color: var(--main-color);
    background-color: var(--main-background-color);
}

.done, .scheduled, .unscheduled {
    float: right;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: var(--msoe-red-color);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.registrar {
  visibility: hidden;
}

@media print {
  :root {
    --msoe-red-color: #C5050C;
    --box-shadow-color: #808080;
    --main-color: #000;
    --main-background-color: #fff;
    --successful-color: #000;
    --unsuccessful-color: #000;
    --wip-color: #000;
    --scheduled-color: #000;
  }

  button, .alignLeft, .alignRight {
    visibility: hidden;
  }

  .unsuccessful, .notTaking {
    text-decoration: line-through;
  }

  .successful::before {
    content: "\2713";
  }

  .projected::before {
    content: "\2611";
  }

  .scheduled::before {
    content: "\2611";
  }

  .unscheduled::before {
    content: "\2610";
  }

  .registrar {
    visibility: visible;
  }

  body {
    font-size: 70%;
  }

  #bigContainer {
    border: none;
  }
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}
